import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkFragment from "../components/fragments/work-fragment"

const WorksDetailPage = (props) => (
  <Layout {...props}>
    <SEO title="Works Detail" />
    <WorkFragment />
  </Layout>
)

export default WorksDetailPage
